import * as React from "react"
import Layout from "../components/common/layout"
import { Separator } from "../components/common/styledComponentsHelper"
import { Link } from "gatsby"

const copyrightHeadlineStyle = "text-uppercase text-bold fw-bold"

export const Head = () => (
  <>
    <title>Urheberrecht | Fachhandwerk360</title>
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const Urheberrecht = () => (
  <Layout>
    <div style={{ marginTop: "85px" }}>
      <Separator />
      <div className="container">
        <h1 className={copyrightHeadlineStyle}>Urheberrecht</h1>
        <p>
          Die In­halte, wie Texte, Bilder, Gra­fiken, unter­liegen dem deutschen
          Ur­heber­recht. Eine Ver­wendung für eigene Zwecke, Verviel­fältigung,
          Bear­beitung oder Ver­breitung in jeglicher Form, bedarf der
          schrift­lichen Ge­nehmig­ung des Autors bzw. Er­stellers. Es ist
          aus­drücklich nicht ge­stattet, die Fotos und Abbildungen von dieser
          Web­seite herunter­zuladen, zu kopieren, abzufoto­grafieren (z.B. über
          die Screen­shot-Funktion) oder in sonstiger Weise für eigene Zwecke zu
          nutzen. Bei Miss­achtung behält sich der Be­treiber der Web­seite
          recht­liche Schritte vor.
        </p>
        <h2 className={copyrightHeadlineStyle}>Bildnachweise</h2>
        <p>
          Es werden Bilder von <b>Adobe Stock</b>, <b>shutterstock</b> und{" "}
          <b>pixabay</b> verwendet:
        </p>
        <ol>
          <li className="list-style-check">
            iceBerg.jpg (AdobeStock_158665720.png) auf der{" "}
            <Link to="/quickcheck">Quick Check-Seite</Link> {">"} Wir steigen
            für Sie tief in Ihre Zahlen ein
            <br />
            <b>Fotograf: </b>
            <a
              href="https://stock.adobe.com/de/contributor/202483008/romolo-tavani?load_type=author&prev_url=detail"
              target="_blank"
              rel="noreferrer"
            >
              Romolo Tavani
            </a>
          </li>
          <li className="list-style-check">
            handwerker_handshake.jpg (iStock_484841870.png) auf der{" "}
            <Link to="/ueber_uns">Über Uns-Seite</Link> {">"} Das ist uns
            wichtig
            <br />
            <b>Fotograf: </b>
            <a
              href="https://www.istockphoto.com/de/portfolio/Szepy?mediatype=photography"
              target="_blank"
              rel="noreferrer"
            >
              Szepy
            </a>
          </li>
        </ol>
      </div>
      <Separator />
    </div>
  </Layout>
)

export default Urheberrecht
